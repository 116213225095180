var stampit = require('stampit/dist/stampit.full');

var Dispatcher = stampit()
.init(function() {
  var _self = this;
  var _callbacks = [];
  var _promises = [];

  /**
   * Register a Store's callback so that it may be invoked by an action.
   * @param  {function} callback The callback to be registered.
   * @return {number} The index of the callback within the _callbacks array.
   */
  this.register = function(callback) {
    _callbacks.push(callback);
    return _callbacks.length - 1;
  };

  /**
   * Dispatch actions to be handle by stores
   * @param  {object} payload The data from the action.
   */
  this.dispatch = function(payload) {
    var resolves = [];
    var rejects = [];

    _promises = _callbacks.map(function(_, i) {
      return new Promise(function(resolve, reject) {
        resolves[i] = resolve;
        rejects[i] = reject;
      });
    });

    _callbacks.forEach(function(callback, i) {
      // Callback can return an obj, to resolve, or a promise, to chain.
      Promise.resolve(callback(payload))
      .then(function() {
        resolves[i](payload);
      })
      .catch(function(err) {
        console.log(err);
        rejects[i](new Error('Dispatcher callback unsuccessful'));
      });
    });

    _promises = [];
  };

  /**
   * A bridge function between the views and the dispatcher, marking the action
   * as a view action.
   * @param  {object} action The data coming from the view.
   */
  this.handleViewAction = function(action) {
    _self.dispatch({
      source: 'VIEW_ACTION',
      action: action
    });
  };

  /**
   * A bridge function between the server and the dispatcher, marking the action
   * as a server action.
   * @param  {object} action The data coming from the server.
   */
  this.handleServerAction = function(action) {
    _self.dispatch({
      source: 'SERVER_ACTION',
      action: action
    });
  };
});

var dispatcher = new Dispatcher();
module.exports = dispatcher;
