/* eslint-env browser */
var stampit = require('stampit/dist/stampit.full');

var SideNav = stampit()
.init(function(options) {
  var _showMenuButtonEl = options.showButtonEl;
  var _sideNavEl = options.sideNavEl;
  var _sideNavContainerEl = options.sideNavContainerEl;

  /**
   * Adds event listeners to show and dismiss side nav
   */
  function _addEventListeners() {
    _showMenuButtonEl.addEventListener('click', _showSideNav);
    _sideNavEl.addEventListener('click', _hideSideNav);
    _sideNavContainerEl.addEventListener('click', _blockPropagation);
  }

  /**
   * Removes event listeners to show and dismiss side nav
   */
  function _removeEventListeners() {
    _showMenuButtonEl.removeEventListener('click', _showSideNav);
    _sideNavEl.removeEventListener('click', _hideSideNav);
    _sideNavContainerEl.removeEventListener('click', _blockPropagation);
  }

  /**
   * Adds relevant classlist to start sideNav show animation
   */
  function _showSideNav() {
    _sideNavEl.classList.add('side-nav--animatable');
    _sideNavEl.classList.add('side-nav--visible');
    _sideNavEl.addEventListener('transitionend', _onTransitionEnd);
  }

  /**
   * Removes relevant classlist to start sideNav hide animation
   */
  function _hideSideNav() {
    _sideNavEl.classList.add('side-nav--animatable');
    _sideNavEl.classList.remove('side-nav--visible');
    _sideNavEl.addEventListener('transitionend', _onTransitionEnd);
  }

  /**
   * Removes animatable class
   */
  function _onTransitionEnd() {
    _sideNavEl.classList.remove('side-nav--animatable');
    _sideNavEl.removeEventListener('transitionend', _onTransitionEnd);
  }

  /**
   * Stops propagation to break
   * @param {obj} evt the event being caught
   */
  function _blockPropagation(evt) {
    // Hack to get menu to play well with pushState Links
    var pushStateRegex = /^(simulator)$/;
    if (evt.target.hasAttribute('href')) {
      var hrefParts = evt.target.getAttribute('href').split('/');
      if (pushStateRegex.test(hrefParts[1])) {
        evt.preventDefault();
        return;
      }
    }

    evt.stopImmediatePropagation();

    // Figure this out??
    if (evt.target.hasAttribute('href')) _sideNavEl.click();
  }

  this.destroy = function() {
    _removeEventListeners();
  };

  _addEventListeners();
});

module.exports = SideNav;
