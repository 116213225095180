var stampit = require('stampit/dist/stampit.full');
var _ = require('lodash');
var BaseStore = require('./base');
var dispatcher = require('../dispatcher');
var constants = require('../constants');

var Sms = stampit()
.init(function() {
  var _self = this;
  var _storeName = 'sms';
  var _changeEvent = 'sms_changed';
  var _defaultState = {
    sms: {},
    threads: {},
    _meta: {
      unread: 0
    }
  };

  /**
   * Get the most recent state of sms's from db
   * @return {object} The states object
   */
  function _getState() {
    var storedSms = _self.store.get(_storeName);
    var storedSmsObj;

    if (storedSms === null) {
      storedSmsObj = _defaultState;
    } else {
      storedSmsObj = JSON.parse(storedSms);
    }

    return storedSmsObj;
  }

  /**
   * Update the state of sms's to db
   * @param {object} state The new state to update to
   */
  function _updateState(state) {
    var stateString = JSON.stringify(state);
    _self.store.set(_storeName, stateString);
  }

  /**
   * Create a new sms entry
   * @param {object} sms The sms to create
   */
  function _createSms(sms) {
    var state = _getState();

    state.sms[sms.id] = sms;

    var newThread;

    if (sms.direction === 'outgoing') {
      if (state.threads[sms.to] === undefined) {
        newThread = {
          id: sms.to,
          sms: [sms.id],
          unread: 0
        };

        state.threads[sms.to] = newThread;
      } else {
        state.threads[sms.to].sms.push(sms.id);
      }
    }

    if (sms.direction === 'incoming') {
      if (state.threads[sms.from] === undefined) {
        newThread = {
          id: sms.from,
          sms: [sms.id],
          unread: 1
        };

        state.threads[sms.from] = newThread;
      } else {
        state.threads[sms.from].sms.push(sms.id);
        ++state.threads[sms.from].unread;
      }
    }

    // Updates Total unread count
    state._meta.unread = Object.keys(state.threads).reduce(function(sum, key) {
      return sum + state.threads[key].unread;
    }, 0);

    _updateState(state);
  }

  /**
   * Updates an sms
   * @param {object} sms The sms object to update
   */
  function _updateSms(sms) {
    var state = _getState();

    var updatedSms = _.assignIn(state.sms[sms.id], sms);
    state.sms[sms.id] = updatedSms;

    _updateState(state);
  }

  /**
   * Emit change event
   */
  function _emitChange() {
    _self.trigger(_changeEvent);
  }

  /**
   * Get the entire collection of sms's.
   * @return {object} The sms collection
   */
  this.getAll = function() {
    var state = _getState();
    var unsortedThreads = [];
    var thread;

    Object.keys(state.threads).forEach(function(k) {
      thread = {
        id: k,
        sms: state.sms[_.last(state.threads[k].sms)],
        unread: state.threads[k].unread
      };

      unsortedThreads.push(thread);
    });

    var timeSortedThreads = unsortedThreads.sort(function(a, b) {
      if (a.sms.createdAt > b.sms.createdAt) {
        return -1;
      }

      if (b.sms.createdAt > a.sms.createdAt) {
        return 1;
      }

      return 0;
    });

    return timeSortedThreads;
  };

  /**
   * Marks a threads sms as read
   * @param {object} thread the thread object
   */
  function _markThreadRead(thread) {
    var state = _getState();

    thread.sms.forEach(function(id) {
      state.sms[id].read = true;
    });

    state._meta.unread -= thread.unread;
    state.threads[thread.id].unread = 0;

    _updateState(state);
  }

  /**
   * Get an sms thread.
   * @param  {string} id The id of the thread
   * @return {object} The sms thread
   */
  this.getThread = function(id) {
    var state = _getState();
    var thread = state.threads[id];

    _markThreadRead(thread);
    state = _getState();

    var returnObj = {
      id: id,
      sms: []
    };

    returnObj.sms = thread.sms.map(function(id) {
      return state.sms[id];
    });

    return returnObj;
  };

  /**
   * Get sms meta info
   * @return {object} The meta object
   */
  this.getMeta = function() {
    var state = _getState();
    return state._meta;
  };

  this.dispatcherIndex = dispatcher.register(function(payload) {
    var action = payload.action;

    switch (action.type) {
      case constants.sms.SEND_SMS:
      case constants.sms.RECEIVE_SMS:
        _createSms(action.sms);
        _emitChange();
        break;
      case constants.sms.SEND_SMS_SUCCESS:
        _updateSms(action.sms);
        _emitChange();
        break;
      case constants.sms.SEND_SMS_FAILURE:
        _updateSms(action.sms);
        _emitChange();
        break;
      default:
        return true;
    }

    return true;
  });
});

var SmsStore = stampit(BaseStore, Sms);
var smsStore = new SmsStore();
module.exports = smsStore;
