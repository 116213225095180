/* eslint-env browser */
var TransitionViewBase = require('./transitionViewBase');
var Handlebars = require('handlebars/runtime');
var helpers = require('../modules/basicHelpers');
var actions = require('../actions');
var stores = require('../stores');

module.exports = TransitionViewBase.extend({
  id: 'vphone-ussd-view',
  className: 'js-vphone-ussd-view vphone-ussd-view',
  events: {
    'click .js-step-back': 'stepBack',
    'click .js-submit-ussd': 'sendUssd',
    'keyup .js-ussd-code': 'ussdCodeChanged'
  },
  onRender: function() {
    console.log('vPhoneUSSDView#onRender method triggered');

    var data = {
      phoneNumber: stores.globals.get('phoneNumber')
    };

    this.$el.html(this.template(data));

    return this;
  },
  afterRender: function() {
    // Cache elem for vanilla JS DOM traversal
    this.rootElement = document.getElementById(this.id);
    this.cacheEls();
    this.rootElement.querySelector('.js-ussd-code').focus();
  },
  cacheEls: function() {
    this.ussdCodeEl = this.rootElement.querySelector('.js-ussd-code');
    this.ussdCodeErrorEl = helpers.closestSibling(this.ussdCodeEl, 'validate-error');
    this.ussdCodeInputSection = helpers.closestParent(this.ussdCodeEl, 'input-section');
  },
  beforeRemove: function() {
    this.resetForm();
  },
  ussdCodeChanged: function(e) {
    if (e.keyCode === 13) return this.sendUssd();
  },
  sendUssd: function() {
    var _self = this;
    var ussdCodeVal = this.ussdCodeEl.value.trim();
    var ussdCodeError;

    /**
     * Validates ussd
     * @return {boolean} true if valid false if not
     */
    function _validateUSSDCode() {
      _self.resetErrors();

      if (ussdCodeVal.length === 0) ussdCodeError = 'Field required';
      if (!ussdCodeError && helpers.regex.ussd.test(ussdCodeVal) === false) ussdCodeError = 'Invalid Value';

      if (ussdCodeError) {
        _self.ussdCodeInputSection.classList.add('error');
        _self.ussdCodeErrorEl.innerText = ussdCodeError;
        return false;
      }

      return true;
    }

    if (_validateUSSDCode() === true) actions.ussd.sendUssd({inputText: ussdCodeVal});
  },
  resetErrors: function() {
    this.ussdCodeInputSection.classList.remove('error');
    this.ussdCodeErrorEl.innerText = '';
  },
  resetForm: function() {
    this.resetErrors();
    this.ussdCodeEl.value = '';
  },
  stepBack: function() {
    window.history.back();
  },
  initialize: function() {
    console.log('Initializing VPhone USSD View...');

    this.template = Handlebars.templates.vPhoneUSSDView;
    this.animateIn = 'fadeIn';
    this.animateOut = 'fadeOut';
  }
});
