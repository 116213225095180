var stampit = require('stampit/dist/stampit.full');
var BaseStore = require('./base');
var dispatcher = require('../dispatcher');
var constants = require('../constants');

var Connection = stampit()
.init(function() {
  var _self = this;
  var _storeName = 'connection';
  var _changeEvent = 'connection_changed';
  var _defaultState = {
    connected: false,
    reconnecting: false,
    reconnectSuccess: false,
    reconnectError: '',
    reconnectMaxTry: false,
    reconnectAbort: false
  };

  /**
   * Get the most recent state of connection from db
   * @return {object} The states object
   */
  function _getState() {
    var storedConnection = _self.store.get(_storeName);
    var storedConnectionObj;

    if (storedConnection === null) {
      storedConnectionObj = _defaultState;
    } else {
      storedConnectionObj = JSON.parse(storedConnection);
    }

    return storedConnectionObj;
  }

  /**
   * Emit change event
   */
  function _emitChange() {
    _self.trigger(_changeEvent);
  }

  /**
   * Update the state of connection to db
   * @param {object} state The new state to update to
   */
  function _updateState(state) {
    var stateString = JSON.stringify(state);
    _self.store.set(_storeName, stateString);
  }

  /**
   * Marks the connection as connected in the db
   */
  function _connected() {
    var state = _getState();
    state.connected = true;
    state.reconnecting = false;
    state.reconnectSuccess = false;
    state.reconnectError = '';
    state.reconnectMaxTry = false;
    state.reconnectAbort = false;
    _updateState(state);
  }

  /**
   * Marks the connection as disconnected in the db
   */
  function _disconnected() {
    var state = _getState();
    state.connected = false;
    state.reconnecting = false;
    state.reconnectSuccess = false;
    state.reconnectError = '';
    state.reconnectMaxTry = false;
    state.reconnectAbort = false;
    _updateState(state);
  }

  /**
   * Sets the connection reconnect success
   * @param {object} connection object containing the reconnect attempts
   */
  function _reconnectSuccess(connection) {
    var state = _getState();
    state.connected = true;
    state.reconnecting = false;
    state.reconnectSuccess = true;
    state.reconnectError = '';
    state.reconnectMaxTry = false;
    state.reconnectAbort = false;
    _updateState(state);
  }

  /**
   * Sets the connection reconnect error
   * @param {object} connection object containing the connection reconnect error
   */
  function _reconnectError(connection) {
    var state = _getState();
    state.connected = false;

    if (state.reconnectMaxTry === true) {
      state.reconnecting = false;
    } else {
      state.reconnecting = true;
    }

    state.reconnectSuccess = false;
    state.reconnectError = connection.error.message;
    state.reconnectAbort = false;
    _updateState(state);
  }

  /**
   * Sets the connection reconnect failure
   */
  function _reconnectFailed() {
    var state = _getState();
    state.connected = false;
    state.reconnecting = false;
    state.reconnectSuccess = false;
    state.reconnectMaxTry = true;
    state.reconnectAbort = false;
    _updateState(state);
  }

  /**
   * Sets the connection abort state
   */
  function _reconnectAbort(connection) {
    var state = {
      connected: false,
      reconnecting: false,
      reconnectSuccess: false,
      reconnectError: connection.message,
      reconnectMaxTry: false,
      reconnectAbort: true
    };
    _updateState(state);
  }

  this.get = function() {
    var state = _getState();
    return state;
  };

  this.dispatcherIndex = dispatcher.register(function(payload) {
    var action = payload.action;

    switch (action.type) {
      case constants.connection.CONNECTION_CONNECTED:
        _connected();
        break;
      case constants.connection.CONNECTION_DISCONNECTED:
        _disconnected();
        _emitChange();
        break;
      case constants.connection.RECONNECT_SUCCESS:
        _reconnectSuccess(action.connection);
        _emitChange();
        break;
      case constants.connection.RECONNECT_ERROR:
        _reconnectError(action.connection);
        _emitChange();
        break;
      case constants.connection.RECONNECT_FAILED:
        _reconnectFailed();
        break;
      case constants.connection.RECONNECT_ABORT:
        _reconnectAbort(action.connection);
        break;
      default:
        return true;
    }

    return true;
  });
});

var ConnectionStore = stampit(BaseStore, Connection);
var connectionStore = new ConnectionStore();
module.exports = connectionStore;
