var stampit = require('stampit/dist/stampit.full');
var dispatcher = require('../dispatcher');
var pubSub = require('../modules/pubSub');
var constants = require('../constants');
var notification = require('./notification');

var UssdActions = stampit()
.init(function() {
  var _self = this;
  this.pubSub = pubSub;
  var _ussdInputRoute = 'ussd:post:input';

  /**
   * Dispatches send ussd action
   * @param {object} ussd Contains ussd details
   */
  this.sendUssd = function(ussd) {
    _self.pubSub.trigger('getSocket', function(err, socket) {
      if (err) {
        console.log(err);
        _self.close();
        notification.receiveNotification({
          message: 'Something blew up, ussd not sent. Retry in a few seconds.',
          type: 'error'
        });
        return;
      }

      socket.emit(_ussdInputRoute, ussd, _ussdSyncHandler.bind(_self));
    });

    _self.loading();
  };

  /**
   * Handles server sync response.
   * For now only to notify if any errors.
   * @param {object} err err from server
   */
  function _ussdSyncHandler(err) {
    if (err) {
      console.log(err);
      _self.close();
      notification.receiveNotification({
        message: 'Something blew up, ussd not sent. Retry in a few seconds.',
        type: 'error'
      });
      return;
    }
  }

  /**
   * Dispatches receive ussd action
   * @param {object} ussd Contains ussd details
   */
  this.receiveUssd = function(ussd) {
    dispatcher.handleServerAction({
      type: constants.ussd.RECEIVE_USSD,
      ussd: ussd
    });
  };

  this.loading = function() {
    dispatcher.handleViewAction({
      type: constants.ussd.LOADING_USSD
    });
  };

  this.close = function() {
    dispatcher.handleViewAction({
      type: constants.ussd.CLOSE_USSD
    });
  };
});

var ussdActions = new UssdActions();
module.exports = ussdActions;
