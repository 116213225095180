var stampit = require('stampit/dist/stampit.full');
var BaseStore = require('./base');
var dispatcher = require('../dispatcher');
var _ = require('lodash');
var constants = require('../constants');

var Notification = stampit()
.init(function() {
  var _self = this;
  var _storeName = 'notification';
  var _changeEvent = 'notification_changed';
  var _defaultState = {
    notifications: []
  };

  /**
   * Get the most recent state of notifications from db
   * @return {object} The states object
   */
  function _getState() {
    var storedNotifications = _self.store.get(_storeName);
    var storedNotificationsObj;

    if (storedNotifications === null) {
      storedNotificationsObj = _defaultState;
    } else {
      storedNotificationsObj = JSON.parse(storedNotifications);
    }

    return storedNotificationsObj;
  }

  /**
   * Update the state of notifications to db
   * @param {object} state The new state to update to
   */
  function _updateState(state) {
    var stateString = JSON.stringify(state);
    _self.store.set(_storeName, stateString);
  }

  /**
   * Create a new notification entry
   * @param {object} notification The notification to create
   */
  function _createNotification(notification) {
    var state = _getState();

    var newNotification = {
      message: notification.message,
      type: notification.type
    };

    state.notifications.push(newNotification);
    _updateState(state);
  }

  /**
   * Emit change event
   */
  function _emitChange() {
    _self.trigger(_changeEvent);
  }

  this.get = function() {
    var state = _getState();
    return _.last(state.notifications);
  };

  this.dispatcherIndex = dispatcher.register(function(payload) {
    var action = payload.action;

    switch (action.type) {
      case constants.notification.RECEIVE_NOTIFICATION:
        _createNotification(action.notification);
        _emitChange();
        break;
      default:
        return true;
    }

    return true;
  });
});

var NotificationStore = stampit(BaseStore, Notification);
var notificationStore = new NotificationStore();
module.exports = notificationStore;
