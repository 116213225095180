/* eslint-env browser */
var TransitionViewBase = require('./transitionViewBase');
var _ = require('lodash');

module.exports = TransitionViewBase.extend({
  el: '#simulator-root-context',
  goto: function(view) {
    var previous = this.currentPage || null;
    var next = view;

    if (previous) {
      var skipTransition = false;

      // // This in 'theory' should not happen
      // // Find better pattern to prevent this
      // if (previous.id === next.id) {
      //   console.log('Its a match!');
      //   if (_.isFunction(previous.beforeRemove)) previous.beforeRemove();
      //   previous.remove();
      //   previous = null;
      //   skipTransition = true;
      // }

      if (skipTransition === false) {
        previous.transitionOut(function() {
          // Before Remove hook
          if (_.isFunction(previous.beforeRemove)) previous.beforeRemove();
          previous.remove();
        });
      }
    }

    next.render({page: true});
    this.$el.html(next.$el);

    next.transitionIn(function() {
      // After Render hook
      if (_.isFunction(next.afterRender)) next.afterRender();
    });

    this.currentPage = next;
  }
});
