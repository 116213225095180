/* eslint-env browser */
var stampit = require('stampit/dist/stampit.full');
var _ = require('lodash');
var _animEndEventName = 'animationend';

var Notification = stampit()
.init(function(options) {
  var _self = this;
  var _ntf;
  var _dismissttl;
  var _active = false;

  var _options = {
    wrapper: document.querySelector('.js-active-page'),
    message: 'Yo',
    type: 'success',
    onOpen: function() {
      return false;
    },
    onClose: function() {
      return false;
    }
  };

  this.show = function() {
    _active = true;
    _ntf.classList.remove('ntf--hide');
    _ntf.classList.add('ntf--show');
    _options.onOpen();
  };

  this.dismiss = function() {
    _active = false;
    if (_dismissttl !== undefined) clearTimeout(_dismissttl);
    _ntf.classList.remove('ntf--show');

    setTimeout(function() {
      _ntf.classList.add('ntf--hide');
      _options.onClose();
    }, 20);

    /**
     * After animation ends remove ntf from DOM
     * @param {Object} ev the event to listen to
     * @return {Boolean} false if the target is not the notification object
     */
    function _onEndAnimationFn(ev) {
      if (ev.target !== _ntf) return false;

      this.removeEventListener(_animEndEventName, _onEndAnimationFn);
      _options.wrapper.removeChild(this);
    }

    _ntf.addEventListener(_animEndEventName, _onEndAnimationFn);
  };

  this.getNtfElem = function() {
    return _ntf;
  };

  /**
   * Sets close handler if close button is available
   */
  function _ntfEvents() {
    if (_ntf.querySelector('.ntf-close') === null) return;

    _ntf.querySelector('.ntf-close').addEventListener('click', function() {
      _self.dismiss();
    });
  }

  /**
   * Create the notification, start things off
   */
  function _createNtf() {
    _ntf = document.createElement('div');
    _ntf.className = 'medium-12 columns ntf-body ntf-effect--slidetop ' + _options.type;
    _ntf.innerHTML = '<div class="row">' +
                     '<div class="medium-8 medium-centered columns message-wrapper">' +
                     (_options.closeButton ? '<span class="ntf-close" title="Close"></span>' : '') +
                     '<span class="message">' +
                     _options.message +
                     '</span>' +
                     '</div>' +
                     '</div>';

    _options.wrapper.insertBefore(_ntf, _options.wrapper.firstChild);

    if (_options.ttl !== undefined) {
      _dismissttl = setTimeout(function() {
        if (_active) _self.dismiss();
      }, _options.ttl);
    }

    _ntfEvents();
  }

  _options = _.extend({}, _options);
  _.extend(_options, options);
  _createNtf();
});

module.exports = Notification;
