var stampit = require('stampit/dist/stampit.full');
var Basil = require('basil.js');
var Backbone = require('backbone');
var _ = require('lodash');

var BaseStore = stampit()
.init(function() {
  // The persistance layer, chosen in order of what is supported
  this.store = new Basil({
    namespace: 'at-simulator',
    storages: ['session', 'memory', 'local', 'cookie'],
    expireDays: 1
  });

  // Extend Backbone events to enable event emitting
  _.assign(this, Backbone.Events);
});

module.exports = BaseStore;
