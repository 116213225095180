/* eslint-env browser */
var TransitionViewBase = require('./transitionViewBase');
var Handlebars = require('handlebars/runtime');
var VanillaModal = require('vanilla-modal');
var helpers = require('../modules/basicHelpers');
var Ps = require('perfect-scrollbar');
var stores = require('../stores');
var actions = require('../actions');

module.exports = TransitionViewBase.extend({
  id: 'vphone-payments-view',
  className: 'js-vphone-payments-view vphone-payments-view',
  events: {
    'click .js-step-back': 'stepBack',
    'click .js-send-payment': 'openSendPaymentModal',
    'click .js-fab-open': 'showFABActions',
    'click .js-fab-close': 'hideFABActions'
  },
  onRender: function(storeChange) {
    console.log('vPhonePaymentsView#onRender method triggered');

    var data = {
      payments: stores.payment.get(),
      phoneNumber: stores.globals.get('phoneNumber')
    };

    // Hack. Read comment below...
    this.beforeRemove();
    this.$el.html(this.template(data));

    // Dynamic on change bypasses transitionViewBase setup
    // Means we need to call beforeRemove and afterRender
    if (storeChange) this.afterRender();
    return this;
  },
  afterRender: function() {
    // Cache elem for vanilla JS DOM traversal
    this.rootElement = document.getElementById(this.id);
    this.contentArea = this.rootElement.querySelector('.js-content-area');
    this.fabWrapper = this.rootElement.querySelector('.js-fab-wrapper');

    this.createVPhoneScrollBar();
    this.createSendPaymentModal();
  },
  beforeRemove: function() {
    this.destroyVPhoneScrollBar();
    this.destroySendPaymentModal();
  },
  createVPhoneScrollBar: function() {
    // Use normal mobile scrolling
    if (helpers.isMobile()) return;

    Ps.initialize(this.contentArea, {
      wheelSpeed: 2,
      scrollXMarginOffset: 10,
      maxScrollbarLength: 300
    });

    this.customScroll = true;
    this.contentArea.scrollTop = this.contentArea.scrollHeight;
  },
  destroyVPhoneScrollBar: function() {
    if (this.customScroll) {
      Ps.destroy(this.contentArea);
      delete this.customScroll;
    }
  },
  createSendPaymentModal: function() {
    var _self = this;

    var spModalWrapper = this.rootElement.querySelector('.send-payment-modal-wrapper');
    var inputSections = spModalWrapper.querySelectorAll('.input-section');
    var errorEls = spModalWrapper.querySelectorAll('.validate-error');
    var pChannelEl = spModalWrapper.querySelector('.js-payment-channel');
    var pAccountEl = spModalWrapper.querySelector('.js-payment-account');
    var pAmountEl = spModalWrapper.querySelector('.js-payment-amount');
    var sendPaymentEl = spModalWrapper.querySelector('.js-submit-payment');

    if (!this.sendPaymentModal) {
      this.sendPaymentModal = new VanillaModal({
        close: '.modal-close',
        clickOutside: false,
        onOpen: function() {
          _addModalEvents();
        },
        onClose: function() {
          _resetForm();
          _removeModalEvents();
        }
      });
    }

    /**
     * Adds send payment modal events
     */
    function _addModalEvents() {
      sendPaymentEl.addEventListener('click', _sendPayment);
    }

    /**
     * Removes send payment modal events
     */
    function _removeModalEvents() {
      sendPaymentEl.removeEventListener('click', _sendPayment);
    }

    /**
     * Sends payment
     */
    function _sendPayment() {
      if (_validatePayment() === false) return;

      var _pAttribs = pAmountEl.value.split(' ');
      _currency = _pAttribs[0];
      _amount = _pAttribs[1];

      var paymentObj = {
        currencyCode: _currency.toUpperCase(),
        paybill: pChannelEl.value.trim(),
        amount: parseInt(_amount, 10)
      };

      var pAccountVal = pAccountEl.value.trim();
      if (pAccountVal.length > 0) paymentObj.account = pAccountVal;

      actions.payment.sendC2b(paymentObj);
      _self.sendPaymentModal.close();
    }

    /**
     * Validates payment
     * @return {boolean} true if valid false if not
     */
    function _validatePayment() {
      _resetErrors();

      // Payment Channel
      var pChannelVal = pChannelEl.value.trim();
      var pChannelErrorEl = helpers.closestSibling(pChannelEl, 'validate-error');
      var pChannelInputSection = helpers.closestParent(pChannelEl, 'input-section');
      var pChannelError;
      // Account
      var pAccountVal = pAccountEl.value.trim();
      var pAccountErrorEl = helpers.closestSibling(pAccountEl, 'validate-error');
      var pAccountInputSection = helpers.closestParent(pAccountEl, 'input-section');
      var pAccountError;
      // Amount
      var pAmountVal = pAmountEl.value.trim();
      var pAmountErrorEl = helpers.closestSibling(pAmountEl, 'validate-error');
      var pAmountInputSection = helpers.closestParent(pAmountEl, 'input-section');
      var pAmountError;

      var _pAttribs = pAmountEl.value.split(' ');
      var _currency = _pAttribs[0] || '';
      var _amount = _pAttribs[1] || '';

      if (pChannelVal.length === 0) pChannelError = 'Provider required';
      if (_currency.length === 0 || _amount.length === 0) pAmountError = 'Currency & Amount required';

      if (!pAmountError && helpers.regex.alpha.test(_currency) === false || _currency.length !== 3) pAmountError = 'Invalid Currency value';
      if (!pAmountError && helpers.regex.numeric.test(_amount) === false) pAmountError = 'Invalid Amount value';

      if (pChannelError) {
        pChannelInputSection.classList.add('error');
        pChannelErrorEl.innerText = pChannelError;
      }

      if (pAccountError) {
        pAccountInputSection.classList.add('error');
        pAccountErrorEl.innerText = pAccountError;
      }

      if (pAmountError) {
        pAmountInputSection.classList.add('error');
        pAmountErrorEl.innerText = pAmountError;
      }

      if (pChannelError || pAccountError || pAmountError) return false;

      return true;
    }

    /**
     * Resets errors in the form
     */
    function _resetErrors() {
      Object.keys(inputSections).forEach(function(k) {
        inputSections[k].classList.remove('error');
      });

      Object.keys(errorEls).forEach(function(k) {
        errorEls[k].innerText = '';
      });
    }

    /**
     * [Resets send payment form to an empty state]
     */
    function _resetForm() {
      _resetErrors();
      pChannelEl.value = '';
      pAccountEl.value = '';
      pAmountEl.value = '';
    }
  },
  openSendPaymentModal: function() {
    this.fabWrapper.classList.remove('open');
    this.sendPaymentModal.open('#send-payment-modal');
  },
  destroySendPaymentModal: function() {
    if (this.sendPaymentModal) {
      this.sendPaymentModal.destroy();
      delete this.sendPaymentModal;
    }
  },
  showFABActions: function() {
    this.fabWrapper.classList.add('open');
  },
  hideFABActions: function() {
    this.fabWrapper.classList.remove('open');
  },
  stepBack: function() {
    window.history.back();
  },
  initialize: function() {
    console.log('Initializing VPhone Payments View...');

    var _self = this;
    this.listenTo(stores.payment, 'payment_changed', function() {
      _self.onRender(true);
    });
    this.template = Handlebars.templates.vPhonePaymentsView;
    this.animateIn = 'fadeIn';
    this.animateOut = 'fadeOut';
  }
});
