/* eslint-env browser */
var stampit = require('stampit/dist/stampit.full');
var io = require('socket.io-client');
var actions = require('../actions');
var pubSub = require('./pubSub');

var WebSocket = stampit()
.init(function() {
  var _self = this;
  this.pubSub = pubSub;
  var _socket;

  /**
   * Creates the web socket
   * @return {promise} promise that resolves the socket creation
   */
  function _createSocket() {
    return Promise.resolve().then(function() {
      var webSocketServerAddress = window.location.origin;
      _socket = io(webSocketServerAddress, {
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        timeout: 10000,
        transports: ['websocket'],
        multiplex: false
      });
    });
  }

  /**
   * Handles reconnection sequence
   */
  function _reconnectSequence() {
    /**
     * Relaunch success handler
     */
    function _relaunchTrueHandler() {
      actions.connection.reconnected();
    }

    /**
     * Relaunch error handler
     * @param {String} message What to display to the user
     */
    function _relaunchFalseHandler(message) {
      actions.connection.reconnectAbort({
        message: 'Unable to reconnect. Re-launch Simulator.'
      });

      // Reload page to take back to start view
      // Session storage guarantees data persistance
      // Re-initiating everything is easier than
      // keeping track of state
      document.location.reload();
    }

    _socket.once('launch:true', _relaunchTrueHandler);
    _socket.once('launch:false', _relaunchFalseHandler);
    _socket.emit('launch', {phoneNumber: _socket.phoneNumber});
  }

  this.registerListeners = function() {
    _socket.on('disconnect', actions.connection.disconnected);
    _socket.on('reconnect', _reconnectSequence);
    _socket.on('reconnect_error', actions.connection.reconnectError);
    _socket.on('reconnect_failed', actions.connection.reconnectFailed);

    // Sms Actions
    _socket.on('sms:receive', actions.sms.receiveSms);

    // Airtime Actions
    _socket.on('airtime', actions.airtime.airtimeConfirmation);

    // Payment Actions
    _socket.on('payment:receive:b2c', actions.payment.receiveB2c);

    // Notification Actions
    _socket.on('notification', actions.notification.receiveNotification);

    // USSD Actions
    _socket.on('ussd', actions.ussd.receiveUssd);
  };

  this.getSocket = function() {
    return new Promise(function(resolve, reject) {
      if (_socket === undefined || _socket === null) {
        return _createSocket()
        .then(function() {
          return resolve(_socket);
        })
        .catch(function(err) {
          reject(err);
        });
      }

      return resolve(_socket);
    });
  };

  this.destroy = function() {
    if (_socket) _socket.destroy();
    _socket = undefined;
  };

  this.pubSub.on('getSocket', function(callback) {
    _self.getSocket()
    .then(function(socket) {
      return callback(null, socket);
    })
    .catch(function(error) {
      return callback(error);
    });
  });
});

var webSocket = new WebSocket();
module.exports = webSocket;
