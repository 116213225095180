var stampit = require('stampit/dist/stampit.full');
var BaseStore = require('./base');
var dispatcher = require('../dispatcher');
var constants = require('../constants');

var Payment = stampit()
.init(function() {
  var _self = this;
  var _storeName = 'payment';
  var _changeEvent = 'payment_changed';
  var _defaultState = {
    payments: [],
    _meta: {
      unread: 0
    }
  };

  /**
   * Get the most recent state of payments from db
   * @return {object} The states object
   */
  function _getState() {
    var storedPayments = _self.store.get(_storeName);
    var storedPaymentsObj;

    if (storedPayments === null) {
      storedPaymentsObj = _defaultState;
    } else {
      storedPaymentsObj = JSON.parse(storedPayments);
    }

    return storedPaymentsObj;
  }

  /**
   * Emit change event
   */
  function _emitChange() {
    _self.trigger(_changeEvent);
  }

  /**
   * Update the state of notifications to db
   * @param {object} state The new state to update to
   */
  function _updateState(state) {
    var stateString = JSON.stringify(state);
    _self.store.set(_storeName, stateString);
  }

  /**
   * Create new payment entry
   * @param {object} payment The payment object
   */
  function _createPaymentMessage(payment) {
    var state = _getState();
    state.payments.push(payment);
    ++state._meta.unread;
    _updateState(state);
  }

  /**
   * Get payment messages.
   * @return {object} The payment messages
   */
  this.get = function() {
    var state = _getState();
    state._meta.unread = 0;
    _updateState(state);
    state = _getState();
    return state.payments;
  };

  /**
   * Get sms meta info
   * @return {object} The meta object
   */
  this.getMeta = function() {
    var state = _getState();
    return state._meta;
  };

  this.dispatcherIndex = dispatcher.register(function(payload) {
    var action = payload.action;

    switch (action.type) {
      case constants.payment.RECEIVE_B2C:
        _createPaymentMessage(action.payment);
        _emitChange();
        break;
      default:
        return true;
    }

    return true;
  });
});

var PaymentStore = stampit(BaseStore, Payment);
var paymentStore = new PaymentStore();
module.exports = paymentStore;
