/* eslint-env browser */
var stampit = require('stampit/dist/stampit.full');
var webSocket = require('./webSocket');
var _heartBeatConfig = {interval: 20000};

var HeartBeat = stampit()
.init(function() {
  var _beating = false;
  var _beatInterval;
  var _socket;

  /**
   * Sends a heartBeat to the server
   */
  function _beat() {
    if (_socket) _socket.emit('Ping');
  }

  /**
   * Starts heartBeat interval
   * @return {Promise} resolves or rejects if heartbeat starts
   */
  this.start = function() {
    return new Promise(function(resolve, reject) {
      if (_beating === false) {
        webSocket.getSocket()
        .then(function(socket) {
          _socket = socket;
          _beatInterval = setInterval(_beat, _heartBeatConfig.interval);
          _beating = true;
          resolve();
        })
        .catch(function(err) {
          reject(err);
        });
      }

      resolve();
    });
  };

  /**
   * Stops heartBeat interval
   */
  this.stop = function() {
    if (_beating === true) {
      clearInterval(_beatInterval);
      _beating = false;
    }
  };
});

var heartBeat = new HeartBeat();
module.exports = heartBeat;
