/* eslint-env browser */
var Backbone = require('backbone');
Backbone.SubRoute = require('backbone.subroute');
var helpers = require('./modules/basicHelpers');
var pubSub = require('./modules/pubSub');

module.exports = Backbone.SubRoute.extend({
  routes: {
    '': 'vPhoneHomeRoute',
    'sms': 'vPhoneSMSRoute',
    'sms/:id': 'vPhoneSMSThreadRoute',
    'voice': 'vPhoneVoiceRoute',
    'ussd': 'vPhoneUSSDRoute',
    'airtime': 'vPhoneAirtimeRoute',
    'payments': 'vPhonePaymentsRoute',
    'switchnumber': 'vPhoneSwitchNumberRoute'

  },
  vPhoneHomeRoute: function() {
    this.app.router.setActiveView();
    var homeView = new this.app.simulator.Views.VPhoneHomeView();
    this.app.simulator.getInstance().goto(homeView);
  },
  vPhoneSMSRoute: function() {
    this.app.router.setActiveView();
    var smsView = new this.app.simulator.Views.VPhoneSMSView();
    this.app.simulator.getInstance().goto(smsView);
  },
  vPhoneSMSThreadRoute: function(threadId) {
    this.app.router.setActiveView();
    var threadView = new this.app.simulator.Views.VPhoneSMSThreadView({
      threadId: threadId
    });

    this.app.simulator.getInstance().goto(threadView);
  },
  vPhoneVoiceRoute: function() {
    this.app.router.setActiveView();
    var voiceView = new this.app.simulator.Views.VPhoneVoiceView();
    this.app.simulator.getInstance().goto(voiceView);
  },
  vPhoneUSSDRoute: function() {
    this.app.router.setActiveView();
    var ussdView = new this.app.simulator.Views.VPhoneUSSDView();
    this.app.simulator.getInstance().goto(ussdView);
  },
  vPhoneAirtimeRoute: function() {
    this.app.router.setActiveView();
    var airtimeView = new this.app.simulator.Views.VPhoneAirtimeView();
    this.app.simulator.getInstance().goto(airtimeView);
  },
  vPhonePaymentsRoute: function() {
    this.app.router.setActiveView();
    var paymentsView = new this.app.simulator.Views.VPhonePaymentsView();
    this.app.simulator.getInstance().goto(paymentsView);
  },
  vPhoneSwitchNumberRoute: function() {
    console.log('Switch number route hit...');
  },
  triggerRoute: function(route) {
    this.navigate(route, {trigger: true});
  },
  delegatePushstateLinks: function() {
    var pushStateRoutes = ['sms', 'voice', 'ussd', 'airtime', 'payments', 'switch-number'];

    helpers.delegatePushstateLinks({
      router: this,
      baseRoute: 'simulator',
      routes: pushStateRoutes,
      delegateEl: document.getElementById('root-context')
    });
  },
  initialize: function(options) {
    console.log('Initializing Simulator Router...');

    this.app = options.app;
    this.pubSub = pubSub;
    this.simulator = options.simulator;
    this.listenTo(this.pubSub, 'trigger:route', this.triggerRoute);
    this.delegatePushstateLinks();
  }
});
