var stampit = require('stampit/dist/stampit.full');
var BaseStore = require('./base');
var dispatcher = require('../dispatcher');
var constants = require('../constants');

var Globals = stampit()
.init(function() {
  var _self = this;
  var _storeName = 'globals';
  var _defaultState = {};

  /**
   * Get the most recent state of globals from db
   * @return {object} The states object
   */
  function _getState() {
    var storedGlobals = _self.store.get(_storeName);
    var storedGlobalsObj;

    if (storedGlobals === null) {
      storedGlobalsObj = _defaultState;
    } else {
      storedGlobalsObj = JSON.parse(storedGlobals);
    }

    return storedGlobalsObj;
  }

  /**
   * Update the state of globals to db
   * @param {object} state The new state to update to
   */
  function _updateState(state) {
    var stateString = JSON.stringify(state);
    _self.store.set(_storeName, stateString);
  }

  /**
   * Sets a global value
   * @param {key} key objects key
   * @param {value} value objects value
   */
  function _set(key, value) {
    var state = _getState();
    state[key] = value;
    _updateState(state);
  }

  /**
   * Get a global value.
   * @param {key} key The values key
   * @return {object} The value
   */
  this.get = function(key) {
    var state = _getState();
    return state[key];
  };

  /**
   * Gets all global values.
   * @return {object} The global values
   */
  this.getAll = function() {
    var state = _getState();
    return state;
  };

  this.dispatcherIndex = dispatcher.register(function(payload) {
    var action = payload.action;

    switch (action.type) {
      case constants.globals.SET_PHONE_NUMBER:
        _set('phoneNumber', action.phoneNumber);
        break;
      default:
        return true;
    }

    return true;
  });
});

var GlobalsStore = stampit(BaseStore, Globals);
var globalsStore = new GlobalsStore();
module.exports = globalsStore;
