/* eslint-env browser */
var Backbone = require('backbone');
var Handlebars = require('handlebars/runtime');
var pubSub = require('../modules/pubSub');

module.exports = Backbone.View.extend({
  tagName: 'li',
  className: 'message-item',
  render: function() {
    this.$el.html(this.template(this.data));
    return this;
  },
  initialize: function(options) {
    this.template = Handlebars.templates.vPhoneSMSItemView;
    this.pubSub = pubSub;
    this.data = options.data;
    this.render();
  }
});
