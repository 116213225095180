/* eslint-env browser */
var Backbone = require('backbone');
var _ = require('lodash');

module.exports = Backbone.View.extend({
  render: function(options) {
    console.debug('Render triggered for view with cid ' + this.cid);

    options = options || {};

    if (options.page === true) {
      this.$el.addClass('page');
    }

    if (_.isFunction(this.onRender)) this.onRender();

    return this;
  },
  transitionIn: function(cb) {
    var view = this;

    var transitionIn = function() {
      view.$el.addClass(view.animateIn + ' animated');
      view.$el.removeClass(view.animateIn + ' animated');

      if (_.isFunction(cb)) {
        console.debug('TransitionIn callback triggered for view with cid ' + view.cid);
        cb();
      }

      // view.$el.one('animationend', function() {
      //   view.$el.removeClass(view.animateIn + ' animated');
      //
      //   if (_.isFunction(cb)) {
      //     cb();
      //     console.log('Callback triggered on transitionend for TransitionIn method');
      //   }
      // });
    };

    _.delay(transitionIn, 0);
  },
  transitionOut: function(cb) {
    var view = this;

    var transitionOut = function() {
      view.$el.addClass(view.animateOut + ' animated');
      view.$el.removeClass(view.animateOut + ' animated');

      if (_.isFunction(cb)) {
        console.debug('TransitionOut callback triggered for view with cid ' + view.cid);
        cb();
      }

      // view.$el.one('animationend', function() {
      //   view.$el.removeClass(view.animateOut + ' animated');
      //
      //   if (_.isFunction(cb)) {
      //     cb();
      //     console.log('Callback triggered on transitionend for TransitionOut method');
      //   }
      // });
    };

    _.delay(transitionOut, 0);
  }
});
