/* eslint-env browser */
var TransitionViewBase = require('./transitionViewBase');
var Handlebars = require('handlebars/runtime');
var helpers = require('../modules/basicHelpers');
var stores = require('../stores');
var Ps = require('perfect-scrollbar');

module.exports = TransitionViewBase.extend({
  id: 'vphone-airtime-view',
  className: 'js-vphone-airtime-view vphone-airtime-view',
  events: {
    'click .js-step-back': 'stepBack'
  },
  onRender: function(storeChange) {
    console.log('vPhoneAirtimeView#onRender method triggered');

    var data = {
      airtime: stores.airtime.get(),
      phoneNumber: stores.globals.get('phoneNumber')
    };

    this.destroyVPhoneScrollBar();
    this.$el.html(this.template(data));

    // Dynamic on change bypasses transitionViewBase setup
    // Means we need to call afterRender locally
    if (storeChange) this.afterRender();
    return this;
  },
  afterRender: function() {
    // Cache elem for vanilla JS DOM traversal
    this.rootElement = document.getElementById(this.id);
    this.contentArea = this.rootElement.querySelector('.js-content-area');

    this.createVPhoneScrollBar();
  },
  beforeRemove: function() {
    this.destroyVPhoneScrollBar();
  },
  createVPhoneScrollBar: function() {
    // Use normal mobile scrolling
    if (helpers.isMobile()) return;

    Ps.initialize(this.contentArea, {
      wheelSpeed: 2,
      scrollXMarginOffset: 10,
      maxScrollbarLength: 300
    });

    this.customScroll = true;
    this.contentArea.scrollTop = this.contentArea.scrollHeight;
  },
  destroyVPhoneScrollBar: function() {
    if (this.customScroll) {
      Ps.destroy(this.contentArea);
      delete this.customScroll;
    }
  },
  stepBack: function() {
    window.history.back();
  },
  initialize: function() {
    console.log('Initializing VPhone Airtime View...');

    var _self = this;
    this.listenTo(stores.airtime, 'airtime_changed', function() {
      _self.onRender(true);
    });
    this.template = Handlebars.templates.vPhoneAirtimeView;
    this.animateIn = 'fadeIn';
    this.animateOut = 'fadeOut';
  }
});
