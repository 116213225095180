/* eslint-env browser */
var TransitionViewBase = require('./transitionViewBase');
var Handlebars = require('handlebars/runtime');
var stores = require('../stores');

module.exports = TransitionViewBase.extend({
  id: 'vphone-voice-view',
  className: 'js-vphone-voice-view vphone-voice-view',
  events: {
    'click .js-step-back': 'stepBack'
  },
  onRender: function() {
    console.log('vPhoneVoiceView#onRender method triggered');

    var data = {
      phoneNumber: stores.globals.get('phoneNumber')
    };

    this.$el.html(this.template(data));

    return this;
  },
  afterRender: function() {
    // Cache elem for vanilla JS DOM traversal
    this.rootElement = document.getElementById(this.id);
  },
  stepBack: function() {
    window.history.back();
  },
  initialize: function() {
    console.log('Initializing VPhone Voice View...');

    this.template = Handlebars.templates.vPhoneVoiceView;
    this.animateIn = 'fadeIn';
    this.animateOut = 'fadeOut';
  }
});
