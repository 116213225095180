var stampit = require('stampit/dist/stampit.full');
var dispatcher = require('../dispatcher');
var _ = require('lodash');
var pubSub = require('../modules/pubSub');
var constants = require('../constants');
var notification = require('./notification');

var PaymentActions = stampit()
.init(function() {
  var _self = this;
  this.pubSub = pubSub;
  var _paymentC2bRoute = 'payment:send:c2b';

  /**
   * Dispatches payment C2B action
   * @param  {object} payment Contains payment details
   */
  this.sendC2b = function(payment) {
    _self.pubSub.trigger('getSocket', function(err, socket) {
      if (err) {
        console.log(err);
        notification.receiveNotification({
          message: 'Something blew up, payment not sent. Retry in a few seconds.',
          type: 'error'
        });
        return;
      }

      socket.emit(_paymentC2bRoute, payment, _paymentC2bSyncHandler.bind(_self));
    });
  };

  /**
   * Handles server sync response.
   * For now only to notify if any errors.
   * @param {object} err err from server
   */
  function _paymentC2bSyncHandler(err) {
    if (err) {
      console.log(err);
      notification.receiveNotification({
        message: 'Something blew up, payment not sent. Retry in a few seconds.',
        type: 'error'
      });
    }
  }

  /**
   * Receives a B2C payment message
   * @param  {object} payment Contains payment details
   */
  this.receiveB2c = function(payment) {
    var paymentObj = _.assignIn(payment, {
      createdAt: Date.now()
    });

    dispatcher.handleServerAction({
      type: constants.payment.RECEIVE_B2C,
      payment: paymentObj
    });
  };
});

var paymentActions = new PaymentActions();
module.exports = paymentActions;
