/* eslint-env browser */
var Backbone = require('backbone');

// Expose to Backbones Debugger
if (window.__backboneAgent) {
  window.__backboneAgent.handleBackbone(Backbone);
}

var app = {
  Views: {
    AppView: require('./views/appView'),
    StartView: require('./views/startView'),
    ActiveView: require('./views/activeView')
  },
  simulator: require('./simulator'),
  Routers: {},
  getInstance: function() {
    if (!this.instance) {
      this.instance = new app.Views.AppView();
    }

    return this.instance;
  },
  init: function(Router) {
    console.log('Initializing App...');

    this.router = new Router();
    this.getInstance();

    Backbone.history.start({
      pushState: true,
      root: '/'
    });
  }
};

module.exports = app;
