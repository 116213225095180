/* eslint-env browser */
module.exports = {
  emptyChildren: function(reference) {
    Object.keys(reference).forEach(function(k) {
      k = parseInt(k, 10);
      while (reference[k].firstChild) {
        reference[k].removeChild(reference[k].firstChild);
      }
    });
  },
  closestParent: function(elem, className) {
    if (!elem || elem === document) {
      return null;
    }

    if (elem.classList.contains(className)) {
      return elem;
    }

    return this.closestParent(elem.parentNode, className);
  },
  closestSibling: function(elem, className) {
    if (!elem || elem === document) {
      return null;
    }

    var sibling = elem.parentNode.firstChild;
    for (; sibling; sibling = sibling.nextSibling) {
      if (sibling.nodeType === 1 && sibling !== elem && sibling.classList.contains(className)) {
        return sibling;
      }
    }

    return null;
  },
  doXHR: function(options) {
    return new Promise(function(resolve, reject) {
      var xhr = new XMLHttpRequest();

      xhr.open(options.method, options.url);

      xhr.timeout = 10000;

      xhr.onload = function() {
        if (xhr.status >= 200 && xhr.status < 300) {
          return resolve(xhr);
        }

        return reject(xhr);
      };

      xhr.ontimeout = function(e) {
        return reject(e);
      };

      xhr.onerror = function(e) {
        return reject(e);
      };

      if (options.headers) {
        Object.keys(options.headers).forEach(function(k) {
          xhr.setRequestHeader(k, options.headers[k]);
        });
      }

      var data = options.data;
      xhr.send(data);
    });
  },
  isMobile: function() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  },
  delegatePushstateLinks: function(options) {
    var pushStateRoutes = options.routes;
    var pushStateRegex = new RegExp('^(' + pushStateRoutes.join('|') + ')$');

    options.delegateEl.addEventListener('click', pushStateHanlder);

    /**
     * [pushStateHanlder description]
     * @param  {[type]} e [description]
     */
    function pushStateHanlder(e) {
      if (e.target.hasAttribute('href') === false) return;

      var link = e.target;
      var href = link.getAttribute('href');

      if (!href || href.charAt(0) === '#') {
        e.preventDefault();
        return;
      }

      var hrefParts = href.split('/');

      if (hrefParts[1] === options.baseRoute) {
        if (pushStateRegex.test(hrefParts[2])) {
          e.preventDefault();
          options.router.navigate(href, {trigger: true});
        }
      }
    }
  },
  setConnectionMessage: function(data) {
    var returnObj = {};

    if (data.connected === true && data.reconnectSuccess === false) {
      returnObj.message = 'All systems go. Happy Testing :)';
      returnObj.type = 'success';
      returnObj.ttl = 6000;
    }

    if (data.connected === false && data.reconnecting === false && data.reconnectMaxTry === false) {
      // returnObj.message = 'Not Connected. <button class="reconnect-button">Reconnect</button>';
      returnObj.message = 'Not Connected.';
      returnObj.type = 'error';
      // returnObj.reconnectButton = true;
    }

    if (data.connected === false && data.reconnecting === true) {
      returnObj.message = 'Not Connected. Reconnecting...';
      returnObj.type = 'error';
    }

    if (data.connected === true && data.reconnectSuccess === true) {
      returnObj.message = 'System Back Online! Reconnected Successfully \\o/';
      returnObj.type = 'success';
      returnObj.ttl = 6000;
    }

    if (data.connected === false && data.reconnectMaxTry === true) {
      returnObj.message = 'Not Connected. Unable to Reconnect. <button class="reconnect-button">Retry</button>';
      returnObj.type = 'error';
      returnObj.reconnectButton = true;
    }

    return returnObj;
  },
  regex: (function() {
    return {
      alpha: /^[a-z]+$/i,
      numeric: /^[0-9\+]+$/i,
      alphaNumeric: /^[a-z0-9]+$/i,
      ussd: /^(\*[0-9]{1,5}(\*[0-9]{1,})*#)$/i
    };
  })(),
  phoneNumberFormat: {
    '+243': '9XXXXXXXX',
    '+251': '7XXXXXXXX',
    '+254': '7XXXXXXXX',
    '+265': '8XXXXXXXX',
    '+234': '7XXXXXXXX',
    '+250': '7XXXXXXXX',
    '+255': '7XXXXXXXX',
    '+256': '7XXXXXXXX',
  }
};
