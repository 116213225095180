var stampit = require('stampit/dist/stampit.full');
var BaseStore = require('./base');
var dispatcher = require('../dispatcher');
var constants = require('../constants');

var Airtime = stampit()
.init(function() {
  var _self = this;
  var _storeName = 'airtime';
  var _changeEvent = 'airtime_changed';
  var _defaultState = {
    airtime: [],
    _meta: {
      unread: 0
    }
  };

  /**
   * Get the most recent state of airtime from db
   * @return {object} The states object
   */
  function _getState() {
    var storedAirtime = _self.store.get(_storeName);
    var storedAirtimeObj;

    if (storedAirtime === null) {
      storedAirtimeObj = _defaultState;
    } else {
      storedAirtimeObj = JSON.parse(storedAirtime);
    }

    return storedAirtimeObj;
  }

  /**
   * Update the state of airtime to db
   * @param {object} state The new state to update to
   */
  function _updateState(state) {
    var stateString = JSON.stringify(state);
    _self.store.set(_storeName, stateString);
  }

  /**
   * Emit change event
   */
  function _emitChange() {
    _self.trigger(_changeEvent);
  }

  /**
   * Create new airtime entry
   * @param {object} airtime The airtime object
   */
  function _createAirtimeMessage(airtime) {
    var state = _getState();
    state.airtime.push(airtime);
    ++state._meta.unread;
    _updateState(state);
  }

  /**
   * Get airtime messages. Also resets unread count
   * @return {object} The airtime messages
   */
  this.get = function() {
    var state = _getState();
    state._meta.unread = 0;
    _updateState(state);
    state = _getState();
    return state.airtime;
  };

  /**
   * Get sms meta info
   * @return {object} The meta object
   */
  this.getMeta = function() {
    var state = _getState();
    return state._meta;
  };

  this.dispatcherIndex = dispatcher.register(function(payload) {
    var action = payload.action;

    switch (action.type) {
      case constants.airtime.AIRTIME_CONFIRMATION:
        _createAirtimeMessage(action.airtime);
        _emitChange();
        break;
      default:
        return true;
    }

    return true;
  });
});

var AirtimeStore = stampit(BaseStore, Airtime);
var airtimeStore = new AirtimeStore();
module.exports = airtimeStore;
