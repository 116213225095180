var stampit = require('stampit/dist/stampit.full');
var dispatcher = require('../dispatcher');
var _ = require('lodash');
var uuidV1 = require('uuid/v1');
var constants = require('../constants');
var pubSub = require('../modules/pubSub');
var notification = require('./notification');

var SmsActions = stampit()
.init(function() {
  var _self = this;
  this.pubSub = pubSub;
  var _messageRoute = 'sms:post:message';
  var _subscriptionRoute = 'sms:post:subscription';

  /**
   * Dispatches send sms success action
   * @param  {object} sms Contains sms details
   */
  this.sendSms = function(sms) {
    _self.pubSub.trigger('getSocket', function(err, socket) {
      if (err) {
        console.log(err);
        _sendSmsFailure({
          sms: {id: sms.id, sent: false},
          reason: 'Something blew up'
        });
        return;
      }

      socket.emit(_messageRoute, sms, _messageSyncHandler.bind(_self));
    });

    dispatcher.handleViewAction({
      type: constants.sms.SEND_SMS,
      sms: sms
    });
  };

  /**
   * Handles server sync response
   * @param  {object} err err from server
   * @param  {result} result result from server
   */
  function _messageSyncHandler(err, result) {
    if (err) {
      console.log(err);
      _sendSmsFailure({
        sms: result.sms,
        reason: err
      });
      return;
    }

    _sendSmsSuccess(result.sms);
  }

  /**
   * Dispatches send sms success action
   * @param  {object} sms Has the sent sms
   */
  function _sendSmsSuccess(sms) {
    dispatcher.handleServerAction({
      type: constants.sms.SEND_SMS_SUCCESS,
      sms: sms
    });
  }

  /**
   * Dispatches send sms failure action
   * @param  {object} obj Has the failed sms and a reason
   */
  function _sendSmsFailure(obj) {
    notification.receiveNotification({
      message: 'Something blew up, sms not sent. Retry in a few seconds.',
      type: 'error'
    });

    dispatcher.handleServerAction({
      type: constants.sms.SEND_SMS_FAILURE,
      sms: obj.sms,
      reason: obj.reason
    });
  }

  this.sendSubscription = function(subscription) {
    _self.pubSub.trigger('getSocket', function(err, socket) {
      if (err) {
        _subscriptionSyncHandler(err);
        return;
      }

      socket.emit(_subscriptionRoute, subscription, _subscriptionSyncHandler.bind(_self));
    });
  };

  /**
   * Handles server sync response.
   * For now only to notify if any errors.
   * @param {object} err err from server
   */
  function _subscriptionSyncHandler(err) {
    if (err) {
      console.log(err);
      notification.receiveNotification({
        message: 'Something blew up, sms not sent. Retry in a few seconds.',
        type: 'error'
      });
    }
  }

  /**
   * Dispatches receive sms action
   * @param  {object} sms Has the sms details
   */
  this.receiveSms = function(sms) {
    var smsObj = _.assignIn(sms, {
      id: uuidV1(),
      direction: 'incoming',
      read: false,
      createdAt: Date.now()
    });

    dispatcher.handleServerAction({
      type: constants.sms.RECEIVE_SMS,
      sms: smsObj
    });
  };
});

var smsActions = new SmsActions();
module.exports = smsActions;
