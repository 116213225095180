var stampit = require('stampit/dist/stampit.full');
var dispatcher = require('../dispatcher');
var constants = require('../constants');

var ConnectionActions = stampit()
.init(function() {
  this.connectionConnected = function() {
    dispatcher.handleViewAction({
      type: constants.connection.CONNECTION_CONNECTED
    });
  };

  this.disconnected = function() {
    dispatcher.handleViewAction({
      type: constants.connection.CONNECTION_DISCONNECTED
    });
  };

  this.reconnected = function(count) {
    dispatcher.handleViewAction({
      type: constants.connection.RECONNECT_SUCCESS,
      connection: {
        reconnectingCount: count
      }
    });
  };

  this.reconnectError = function(error) {
    dispatcher.handleViewAction({
      type: constants.connection.RECONNECT_ERROR,
      connection: {
        error: error
      }
    });
  };

  this.reconnectFailed = function() {
    dispatcher.handleViewAction({
      type: constants.connection.RECONNECT_FAILED
    });
  };

  this.reconnectAbort = function(opts) {
    dispatcher.handleViewAction({
      type: constants.connection.RECONNECT_ABORT,
      connection: {
        abort: true,
        message: opts.message
      }
    });
  }
});

var connectionActions = new ConnectionActions();
module.exports = connectionActions;
