/* eslint-env browser */
var Backbone = require('backbone');
var Handlebars = require('handlebars/runtime');
var VanillaModal = require('vanilla-modal');
var helpers = require('../modules/basicHelpers');
var pubSub = require('../modules/pubSub');
var stores = require('../stores');
var actions = require('../actions');
var Notification = require('../modules/notification');

module.exports = Backbone.View.extend({
  id: 'active-view',
  className: 'js-active-view active-view',
  render: function() {
    console.log('rendering active view');

    var templateOptions = {mobile: false};

    if (helpers.isMobile()) templateOptions.mobile = true;

    this.$el.html(this.template(templateOptions));

    return this;
  },
  afterRender: function() {
    this.rootElement = document.getElementById(this.id);
    this.udModalWrapper = this.rootElement.querySelector('.js-ussd-modal-wrapper');
    this.connectionNotify();
    this.createUssdModal();
    // Unset in case of page refresh since session storage persists
    actions.ussd.close();
  },
  transactionalNotify: function() {
    var _self = this;
    var latestNotification = stores.notification.get();

    var notificationOptions = {
      wrapper: _self.rootElement.querySelector('.transactional-dialogue'),
      message: latestNotification.message,
      type: latestNotification.type,
      closeButton: true,
      ttl: 10000
    };

    var transactionalNotification = new Notification(notificationOptions);
    transactionalNotification.show();
  },
  connectionNotify: function() {
    if (this.connectionNotification) this.connectionNotification.dismiss();

    var _self = this;
    var connectionState = stores.connection.get();

    if (connectionState.connected === true) {
      this.rootElement.classList.remove('disconnected');
      this.rootElement.classList.add('connected');
    } else {
      this.rootElement.classList.remove('connected');
      this.rootElement.classList.add('disconnected');
      actions.ussd.close();
    }

    var connectionMessage = helpers.setConnectionMessage(connectionState);
    var notificationOptions = {
      wrapper: _self.rootElement.querySelector('.connection-dialogue'),
      message: connectionMessage.message,
      type: connectionMessage.type
    };

    if (connectionMessage.ttl) notificationOptions.ttl = connectionMessage.ttl;
    if (connectionMessage.reconnectButton) {
      notificationOptions.onOpen = function() {
        _setReconnectButtonEvents();
      };

      notificationOptions.onClose = function() {
        _removeReconnectButtonEvents();
      };
    }

    /**
     * Sets event for reconnect button
     */
    function _setReconnectButtonEvents() {
      var ntfElem = _self.connectionNotification.getNtfElem();
      _self.reconnectButton = ntfElem.querySelector('.reconnect-button');
      _self.reconnectButton.addEventListener('click', _self.reconnectHandler);
    }

    /**
     * Removes event for reconnect button
     */
    function _removeReconnectButtonEvents() {
      _self.reconnectButton.removeEventListener('click', _self.reconnectHandler);
    }

    this.connectionNotification = new Notification(notificationOptions);
    this.connectionNotification.show();
  },
  reconnectHandler: function() {
    console.log('Reconnecting...');
  },
  createUssdModal: function() {
    var _self = this;

    if (!this.ussdModal) {
      this.ussdModal = new VanillaModal({
        clickOutside: false,
        onClose: function() {
          actions.ussd.close();
          _self.removeUssdEvents();
        }
      });
    }
  },
  ussdChanged: function() {
    if (!this.ussdModal.isOpen) this.ussdModal.open('#ussd-modal');
    var ussdState = stores.ussd.get();
    if (ussdState.transactionId.length > 0) this.ussdTransactionId = ussdState.transactionId;
    this.setUssdContent(ussdState);
  },
  setUssdContent: function(state) {
    var _self = this;
    this.removeUssdEvents();

    // Setting Loader
    if (state.active && state.text.length === 0) this.udModalWrapper.innerHTML = this.ussdRunningTemplate();

    // Setting Text
    if (state.active && state.text.length > 0) {
      var ussdResultOptions = {
        text: state.text,
        con: false
      };

      if (state.status === 'CON') ussdResultOptions.con = true;

      this.udModalWrapper.innerHTML = this.ussdResultTemplate(ussdResultOptions);

      if (state.status === 'CON') this.udModalWrapper.querySelector('.js-ussd-input').focus();

      var cancelBtnEl = this.udModalWrapper.querySelector('.js-ussd-cancel');
      if (cancelBtnEl !== null) {
        cancelBtnEl.addEventListener('click', _self.closeUssdModal.bind(_self));

        this.ussdEvents.push({
          event: 'click',
          target: cancelBtnEl,
          handler: _self.closeUssdModal
        });
      }

      var sendBtnEl = this.udModalWrapper.querySelector('.js-ussd-send');
      if (sendBtnEl !== null) {
        sendBtnEl.addEventListener('click', _self.sendUssd.bind(_self));

        this.ussdEvents.push({
          event: 'click',
          target: sendBtnEl,
          handler: _self.sendUssd
        });
      }

      var inputEl = this.udModalWrapper.querySelector('.js-ussd-input');
      if (inputEl !== null) {
        inputEl.addEventListener('keyup', _self.ussdInputChanged.bind(_self));

        this.ussdEvents.push({
          event: 'keyup',
          target: sendBtnEl,
          handler: _self.ussdInputChanged
        });
      }
    }
  },
  removeUssdEvents: function() {
    if (this.ussdEvents.length === 0) return;
    var _self = this;
    this.ussdEvents.forEach(function(e, i) {
      e.target.removeEventListener(e.event, e.handler);
      _self.ussdEvents.splice(i, 1);
    });
  },
  closeUssdModal: function() {
    this.ussdModal.close();
  },
  sendUssd: function() {
    var inputText = this.udModalWrapper.querySelector('.js-ussd-input').value.trim();
    var ussdObj = {inputText: inputText};
    if (this.ussdTransactionId) ussdObj.transactionId = this.ussdTransactionId;
    actions.ussd.sendUssd(ussdObj);
  },
  ussdInputChanged: function(e) {
    if (e.keyCode === 13) return this.sendUssd();
  },
  ussdEvents: [],
  initialize: function() {
    console.log('Initializing Active View...');
    this.pubSub = pubSub;
    this.listenTo(stores.connection, 'connection_changed', this.connectionNotify);
    this.listenTo(stores.notification, 'notification_changed', this.transactionalNotify);
    this.listenTo(stores.ussd, 'ussd_changed', this.ussdChanged);
    this.template = Handlebars.templates.activeView;
    this.ussdRunningTemplate = Handlebars.templates.ussdRunning;
    this.ussdResultTemplate = Handlebars.templates.ussdResult;
    this.render();
  }
});
