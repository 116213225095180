var stampit = require('stampit/dist/stampit.full');
var dispatcher = require('../dispatcher');
var constants = require('../constants');

var GlobalsActions = stampit()
.init(function() {
  /**
   * Dispatches set phone number action
   * @param {string} phoneNumber the phoneNUmber being set
   */
  this.setPhoneNumber = function(phoneNumber) {
    dispatcher.handleServerAction({
      type: constants.globals.SET_PHONE_NUMBER,
      phoneNumber: phoneNumber
    });
  };
});

var globalsActions = new GlobalsActions();
module.exports = globalsActions;
