var stampit = require('stampit/dist/stampit.full');
var dispatcher = require('../dispatcher');
var constants = require('../constants');

var NotificationActions = stampit()
.init(function() {
  /**
   * Dispatches receive notification action
   * @param {string} notification the notification being received
   */
  this.receiveNotification = function(notification) {
    dispatcher.handleServerAction({
      type: constants.notification.RECEIVE_NOTIFICATION,
      notification: notification
    });
  };
});

var notificationActions = new NotificationActions();
module.exports = notificationActions;
