/* eslint-env browser */
var Backbone = require('backbone');
var app = require('./app');
var heartBeat = require('./modules/heartBeat');
var pubSub = require('./modules/pubSub');
var _ = require('lodash');
var actions = require('./actions');
var stores = require('./stores');

module.exports = Backbone.Router.extend({
  routes: {
    '': 'launchRoute',
    'simulator': 'invokeSimulatorModule',
    'simulator/*subroute': 'invokeSimulatorModule'
  },
  launchRoute: function() {
    var options = {
      data: {countries: window.ansa.countries},
    };

    var launchView = new app.Views.StartView(options);
    app.getInstance().goto(launchView);
    this.activeViewSet = false;
  },
  launchSimulator: function(phoneNumber) {
    this.phoneNumber = phoneNumber;
    actions.globals.setPhoneNumber(phoneNumber);
    this.navigate('simulator', {trigger: true});
  },
  invokeSimulatorModule: function() {
    var _self = this;

    if (_beforeHook() === true) {
      heartBeat.start()
      .then(function() {
        if (!app.Routers.Simulator) {
          app.Routers.Simulator = new app.simulator.Router('simulator', {
            createTrailingSlashRoutes: true,
            app: app
          });
        }
      })
      .catch(function(err) {
        console.log(err);
      });
    } else {
      app.router.navigate('/', {trigger: true});
    }

    /**
     * Checks whether phoneNumber is set before launching
     * @return {boolean} true/false on whether phoneNumber is set
     */
    function _beforeHook() {
      var connectionState = stores.connection.get();

      if (_self.phoneNumber === undefined || connectionState.reconnectAbort === true) return false;
      return true;
    }
  },
  setActiveView: function() {
    if (this.activeViewSet === true) return;

    var activeView = new app.Views.ActiveView();
    app.getInstance().$el.html(activeView.$el);
    if (_.isFunction(activeView.afterRender)) activeView.afterRender();
    this.activeViewSet = true;
  },
  initialize: function() {
    console.log('Initializing App Router...');
    this.activeViewSet = false;
    this.pubSub = pubSub;
    this.listenToOnce(this.pubSub, 'launch:true', this.launchSimulator);
  }
});
