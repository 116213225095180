var stampit = require('stampit/dist/stampit.full');
var BaseStore = require('./base');
var dispatcher = require('../dispatcher');
var constants = require('../constants');
var _ = require('lodash');

var Ussd = stampit()
.init(function() {
  var _self = this;
  var _storeName = 'ussd';
  var _changeEvent = 'ussd_changed';
  var _defaultState = {
    active: false,
    text: '',
    transactionId: '',
    status: ''
  };

  /**
   * Get the most recent state of ussd from db
   * @return {object} The states object
   */
  function _getState() {
    var storedUssd = _self.store.get(_storeName);
    var storedUssdObj;

    if (storedUssd === null) {
      storedUssdObj = _defaultState;
    } else {
      storedUssdObj = JSON.parse(storedUssd);
    }

    return storedUssdObj;
  }

  /**
   * Update the state of notifications to db
   * @param {object} state The new state to update to
   */
  function _updateState(state) {
    var stateString = JSON.stringify(state);
    _self.store.set(_storeName, stateString);
  }

  /**
   * Emit change event
   */
  function _emitChange() {
    _self.trigger(_changeEvent);
  }

  /**
   * Sets USSD to loading state
   */
  function _loading() {
    var state = _getState();
    state.active = true;
    state.text = '';
    state.status = '';
    _updateState(state);
  }

  /**
   * Sets USSD display text
   * @param {object} ussd contains ussd details
   * @return {boolean} true or false if ussd state was received
   */
  function _receiveUssd(ussd) {
    var state = _getState();

    if (state.active === true) {
      if (state.transactionId === ussd.transactionId || state.transactionId === '') {
        state.text = ussd.text;
        state.transactionId = ussd.transactionId;
        state.status = ussd.status;
        _updateState(state);
        return true;
      }

      // Conflicting ussd calls;
      return false;
    }

    state.active = true;
    state.text = ussd.text;
    state.transactionId = ussd.transactionId;
    state.status = ussd.status;
    _updateState(state);
    return true;
  }

  /**
   * Sets USSD status to close/back to default
   */
  function _close() {
    // Weird default state bug causing
    // repetiotion of this object
    var state = {
      active: false,
      text: '',
      transactionId: '',
      status: ''
    };

    _updateState(state);
  }

  this.get = function() {
    var state = _getState();
    return state;
  };

  this.dispatcherIndex = dispatcher.register(function(payload) {
    var action = payload.action;

    switch (action.type) {
      case constants.ussd.LOADING_USSD:
        _loading();
        _emitChange();
        break;
      case constants.ussd.RECEIVE_USSD:
        // Delay needed as rapid DOM updates cause
        // stutters when changing modals consecutively
        _.delay(function() {
          if (_receiveUssd(action.ussd)) _emitChange();
        }, 800);
        break;
      case constants.ussd.CLOSE_USSD:
        _close();
        break;
      default:
        return true;
    }

    return true;
  });
});

var UssdStore = stampit(BaseStore, Ussd);
var ussdStore = new UssdStore();
module.exports = ussdStore;
