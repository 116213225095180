/* eslint-env browser */
var TransitionViewBase = require('./transitionViewBase');
var Handlebars = require('handlebars/runtime');
var helpers = require('../modules/basicHelpers');
var Ps = require('perfect-scrollbar');
var VanillaModal = require('vanilla-modal');
var _ = require('lodash');
var VPhoneSMSItemView = require('./vPhoneSMSItemView');
var actions = require('../actions');
var stores = require('../stores');
var uuidV1 = require('uuid/v1');

module.exports = TransitionViewBase.extend({
  id: 'vphone-sms-view',
  className: 'js-vphone-sms-view vphone-sms-view',
  onRender: function() {
    console.log('vPhoneSMSView#onRender method triggered');

    var data = {
      phoneNumber: stores.globals.get('phoneNumber')
    };

    this.$el.html(this.template(data));
    return this;
  },
  events: {
    'click .js-step-back': 'stepBack',
    'click .js-new-message': 'openNewMessageModal',
    'click .js-subscription-message': 'openSubscriptionMessageModal',
    'click .js-fab-open': 'showFABActions',
    'click .js-fab-close': 'hideFABActions'
  },
  afterRender: function() {
    // Cache elem for vanilla JS DOM traversal
    this.rootElement = document.getElementById(this.id);
    this.contentArea = this.rootElement.querySelector('.js-content-area');
    this.fabWrapper = this.rootElement.querySelector('.js-fab-wrapper');

    this.loadSms();
    this.createVPhoneScrollBar();
    this.createNewMessageModal();
    this.createSubscriptionMessageModal();
  },
  beforeRemove: function() {
    this.removeContent();
    this.destroyVPhoneScrollBar();
    this.destroyNewMessageModal();
    this.destroySubscriptionMessageModal();
  },
  loadSms: function(storeChange) {
    var _self = this;

    var smsThreads = stores.sms.getAll();

    if (smsThreads.length === 0) {
      this.contentArea.classList.add('empty');
      return;
    }

    this.contentArea.classList.remove('empty');
    _.reverse(smsThreads).forEach(function(thread) {
      _self.setContent(thread);
    });

    if (storeChange) this.updateVPhoneScrollBar();
  },
  setContent: function(thread) {
    var _self = this;

    var messageList = this.rootElement.querySelector('.js-message-list');
    var messageItem;

    if (_self.threadsHash[thread.id] === undefined) {
      messageItem = new VPhoneSMSItemView({data: thread});
      messageList.insertBefore(messageItem.el, messageList.firstChild);
      _self.threadsHash[thread.id] = messageItem;
    } else {
      _self.threadsHash[thread.id].remove();
      _self.threadsHash[thread.id].data = thread;
      _self.threadsHash[thread.id].render();
      _self.threadsHash[thread.id].delegateEvents();
      messageList.insertBefore(_self.threadsHash[thread.id].el, messageList.firstChild);
    }
  },
  removeContent: function() {
    var _self = this;

    Object.keys(this.threadsHash).forEach(function(k) {
      _self.threadsHash[k].remove();
      delete _self.threadsHash[k];
    });
  },
  createVPhoneScrollBar: function() {
    // Use normal mobile scrolling
    if (helpers.isMobile()) return;

    Ps.initialize(this.contentArea, {
      wheelSpeed: 2,
      scrollXMarginOffset: 10,
      maxScrollbarLength: 300
    });

    this.customScroll = true;
  },
  updateVPhoneScrollBar: function() {
    if (this.customScroll) Ps.update(this.contentArea);
  },
  destroyVPhoneScrollBar: function() {
    if (this.customScroll) {
      Ps.destroy(this.contentArea);
      delete this.customScroll;
    }
  },
  createNewMessageModal: function() {
    var _self = this;

    var smModalWrapper = this.rootElement.querySelector('.send-message-modal-wrapper');
    var inputSections = smModalWrapper.querySelectorAll('.input-section');
    var errorEls = smModalWrapper.querySelectorAll('.validate-error');
    var toFormEl = smModalWrapper.querySelector('.js-message-recepient');
    var messageFormEl = smModalWrapper.querySelector('.js-message-text');
    var sendFormEl = smModalWrapper.querySelector('.js-submit-new-message');

    if (!this.newMessageModal) {
      this.newMessageModal = new VanillaModal({
        close: '.modal-close',
        clickOutside: false,
        onOpen: function() {
          _addModalEvents();
        },
        onClose: function() {
          _resetForm();
          _removeModalEvents();
        }
      });
    }

    /**
     * Adds send message modal events
     */
    function _addModalEvents() {
      sendFormEl.addEventListener('click', _sendNewMessage);
    }

    /**
     * Removes send message modal events
     */
    function _removeModalEvents() {
      sendFormEl.removeEventListener('click', _sendNewMessage);
    }

    /**
     * Sends sms
     */
    function _sendNewMessage() {
      if (_validateNewMessage() === false) return;

      var smsObj = {
        id: uuidV1(),
        direction: 'outgoing',
        sent: false,
        read: true,
        createdAt: Date.now(),
        to: toFormEl.value.trim(),
        text: messageFormEl.value
      };

      actions.sms.sendSms(smsObj);
      _self.newMessageModal.close();
    }

    /**
     * Validates new message
     * @return {boolean} true if valid false if not
     */
    function _validateNewMessage() {
      _resetErrors();
      var toVal = toFormEl.value.trim();
      var toErrorEl = helpers.closestSibling(toFormEl, 'validate-error');
      var toInputSection = helpers.closestParent(toFormEl, 'input-section');
      var toError;

      if (toVal.length === 0) {
        toError = 'Field required';
      }

      if (!toError && helpers.regex.numeric.test(toVal) === false) toError = 'Invalid Value';

      if (toError) {
        toInputSection.classList.add('error');
        toErrorEl.innerText = toError;
        return false;
      }

      return true;
    }

    /**
     * Resets errors in the form
     */
    function _resetErrors() {
      Object.keys(inputSections).forEach(function(k) {
        inputSections[k].classList.remove('error');
      });

      Object.keys(errorEls).forEach(function(k) {
        errorEls[k].innerText = '';
      });
    }

    /**
     * Resets new message form to an empty state
     */
    function _resetForm() {
      _resetErrors();
      toFormEl.value = '';
      messageFormEl.value = '';
    }
  },
  openNewMessageModal: function() {
    this.fabWrapper.classList.remove('open');
    this.newMessageModal.open('#send-message-modal');
  },
  destroyNewMessageModal: function() {
    if (this.newMessageModal) {
      this.newMessageModal.destroy();
      delete this.newMessageModal;
    }
  },
  createSubscriptionMessageModal: function() {
    var _self = this;

    var sbModalWrapper = this.rootElement.querySelector('.subscription-message-modal-wrapper');
    var inputSections = sbModalWrapper.querySelectorAll('.input-section');
    var subscriptionTrueEl = sbModalWrapper.querySelector('#subscriptionTrue');
    var subscriptionFalseEl = sbModalWrapper.querySelector('#subscriptionFalse');
    var errorEls = sbModalWrapper.querySelectorAll('.validate-error');
    var shortCodeEl = sbModalWrapper.querySelector('.js-subscription-shortcode');
    var keywordEl = sbModalWrapper.querySelector('.js-subscription-keyword');
    var sendFormEl = sbModalWrapper.querySelector('.js-submit-subscription-message');

    if (!this.subscriptionMessageModal) {
      this.subscriptionMessageModal = new VanillaModal({
        close: '.modal-close',
        clickOutside: false,
        onOpen: function() {
          _addModalEvents();
        },
        onClose: function() {
          _resetForm();
          _removeModalEvents();
        }
      });
    }

    /**
     * Adds subscription message modal events
     */
    function _addModalEvents() {
      sendFormEl.addEventListener('click', _sendSubscriptionMessage);
    }

    /**
     * Removes subscription message modal events
     */
    function _removeModalEvents() {
      sendFormEl.removeEventListener('click', _sendSubscriptionMessage);
    }

    /**
     * Sends the subscription message
     */
    function _sendSubscriptionMessage() {
      if (_validateSubscriptionMessage() === false) return;

      var subscriptionObj = {
        shortCode: shortCodeEl.value,
        keyword: keywordEl.value,
        subscribe: (function() {
          if (subscriptionTrueEl.checked === true) return true;
          return false;
        })()
      };

      actions.sms.sendSubscription(subscriptionObj);
      _self.subscriptionMessageModal.close();
    }

    /**
     * Validates the subscription message
     * @return {Boolean} true or false if message is valid
     */
    function _validateSubscriptionMessage() {
      _resetErrors();
      var shortCodeVal = shortCodeEl.value.trim();
      var shortCodeErrorEl = helpers.closestSibling(shortCodeEl, 'validate-error');
      var shortCodeInputSection = helpers.closestParent(shortCodeEl, 'input-section');
      var keywordVal = keywordEl.value.trim();
      var keywordErrorEl = helpers.closestSibling(keywordEl, 'validate-error');
      var keywordInputSection = helpers.closestParent(keywordEl, 'input-section');
      var actionErrorEl = helpers.closestSibling(subscriptionTrueEl, 'validate-error');
      var actionInputSection = helpers.closestParent(subscriptionTrueEl, 'input-section');
      var shortCodeError;
      var keywordError;
      var actionError;

      if (shortCodeVal.length === 0) shortCodeError = 'Field Required';

      if (!shortCodeError && helpers.regex.numeric.test(shortCodeVal) === false) shortCodeError = 'Invalid Value';

      if (shortCodeError) {
        shortCodeInputSection.classList.add('error');
        shortCodeErrorEl.innerText = shortCodeError;
      }

      if (keywordVal.length === 0) keywordError = 'Field Required';

      if (!keywordError && helpers.regex.alphaNumeric.test(keywordVal) === false) keywordError = 'Invalid Value';

      if (keywordError) {
        keywordInputSection.classList.add('error');
        keywordErrorEl.innerText = keywordError;
      }

      if (subscriptionTrueEl.checked === false && subscriptionFalseEl.checked === false) actionError = 'Field Required';

      if (actionError) {
        actionInputSection.classList.add('error');
        actionErrorEl.innerText = actionError;
      }

      if (shortCodeError || keywordError || actionError) return false;

      return true;
    }

    /**
     * Resets errors in the form
     */
    function _resetErrors() {
      Object.keys(inputSections).forEach(function(k) {
        inputSections[k].classList.remove('error');
      });

      Object.keys(errorEls).forEach(function(k) {
        errorEls[k].innerText = '';
      });
    }

    /**
     * Resets the subscription message form
     */
    function _resetForm() {
      _resetErrors();
      shortCodeEl.value = '';
      keywordEl.value = '';
      subscriptionTrueEl.checked = false;
      subscriptionFalseEl.checked = false;
    }
  },
  openSubscriptionMessageModal: function() {
    this.fabWrapper.classList.remove('open');
    this.subscriptionMessageModal.open('#subscription-message-modal');
  },
  destroySubscriptionMessageModal: function() {
    if (this.subscriptionMessageModal) {
      this.subscriptionMessageModal.destroy();
      delete this.subscriptionMessageModal;
    }
  },
  threadsHash: {},
  showFABActions: function() {
    this.fabWrapper.classList.add('open');
  },
  hideFABActions: function() {
    this.fabWrapper.classList.remove('open');
  },
  stepBack: function() {
    window.history.back();
  },
  initialize: function(options) {
    console.log('Initializing VPhone SMS View...');

    var _self = this;
    this.listenTo(stores.sms, 'sms_changed', function() {
      _self.loadSms(true);
    });
    this.template = Handlebars.templates.vPhoneSMSView;
    this.animateIn = 'fadeIn';
    this.animateOut = 'fadeOut';
  }
});
