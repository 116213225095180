/* eslint-env browser */
var moment = require('moment');

module.exports = function(Handlebars) {
  Handlebars.registerHelper('debug', function(data) {
    console.log('Current Context');
    console.log('====================');
    console.log(this);

    if (data) {
      console.log('Value');
      console.log('====================');
      console.log(data);
    }
  });

  Handlebars.registerHelper('timeFormat', function(time) {
    return moment(time).fromNow();
  });

  Handlebars.registerHelper('isEmpty', function(data) {
    return (data.length > 0 ? '' : 'empty');
  });

  Handlebars.registerHelper('replaceLn', function(string) {
    return string.replace(/\n|(\r\n)/g, '<br/>');
  });

  Handlebars.registerHelper('outputRecepient', function(data) {
    if (data.direction === 'outgoing') {
      return data.to;
    }

    if (data.direction === 'incoming') {
      return data.from;
    }
  });

  Handlebars.registerHelper('outputUnreadCount', function(unreadCount) {
    if (unreadCount > 0) return '<span class="unread-wrapper">' + unreadCount + '</span>';
  });

  Handlebars.registerHelper('outputTimeOrFailed', function(data) {
    if (data.direction === 'incoming' || data.sent === true) {
      return '<span class="time">' +
              Handlebars.helpers.timeFormat(data.createdAt) +
              '</span>';
    }

    if (data.direction === 'outgoing' && data.sent === false) {
      return '<div class="failed">' +
             '<span class="icon">' +
             '<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0z" fill="none"/><path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"/></svg>' +
             '</span>' +
             '<span class="text">Failed</span>' +
             '</div>';
    }
  });
};
