/* eslint-env browser */
var simulator = {
  Views: {
    SimulatorView: require('./views/simulatorView'),
    VPhoneHomeView: require('./views/vPhoneHomeView'),
    VPhoneSMSView: require('./views/vPhoneSMSView'),
    VPhoneSMSThreadView: require('./views/vPhoneSMSThreadView'),
    VPhoneUSSDView: require('./views/vPhoneUSSDView'),
    VPhonePaymentsView: require('./views/vPhonePaymentsView'),
    VPhoneVoiceView: require('./views/vPhoneVoiceView'),
    VPhoneAirtimeView: require('./views/vPhoneAirtimeView')
  },
  Router: require('./simulatorRouter'),
  getInstance: function() {
    if (!this.instance) {
      this.instance = new simulator.Views.SimulatorView();
    }

    return this.instance;
  }
};

module.exports = simulator;
