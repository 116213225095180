/* eslint-env browser */
var TransitionViewBase = require('./transitionViewBase');
var Handlebars = require('handlebars/runtime');
var helpers = require('../modules/basicHelpers');
var webSocket = require('../modules/webSocket');
var pubSub = require('../modules/pubSub');
var stores = require('../stores');
var actions = require('../actions');

module.exports = TransitionViewBase.extend({
  id: 'start-view',
  className: 'js-start-view start-view',
  events: {
    'change .js-phone-country': 'setCountryCode',
    'keyup .js-phone-number': 'submitPhoneNumber',
    'click .js-launch-sandbox': 'launchSandbox'
  },
  onRender: function() {
    this.$el.html(this.template(this.data));
    return this;
  },
  afterRender: function() {
    // Cache elem for vanilla JS DOM traversal
    this.rootElement = document.getElementById(this.id);
    this.resolveReconnectAbort();
  },
  launchSandbox: function() {
    var _self = this;
    var _launching = false;
    var phoneNumber = this.getPhoneNumber();
    var launchError = this.rootElement.querySelector('.js-launch-error');
    var launchButton = this.rootElement.querySelector('.js-launch-sandbox');

    if (phoneNumber && _launching === false) {
      _launching = true;
      launchButton.innerText = 'Launching...';
      launchButton.setAttribute('disabled', 'disabled');
      launchError.innerText = '';

      return webSocket.getSocket()
      .then(function(socket) {
        /**
         * Handles the launch true event
         * @param {String} phoneNumber The phoneNumber in use for the session
         */
        function _launchTrueHandler(phoneNumber) {
          socket.phoneNumber = phoneNumber;
          webSocket.registerListeners();
          actions.connection.connectionConnected();
          _self.pubSub.trigger('launch:true', phoneNumber);
          socket.off('launch:failed', _launchFalseHandler);
        }

        /**
         * Handles the launch false event
         * @param {String} message The message to the user
         */
        function _launchFalseHandler(message) {
          launchError.innerText = message;
          _launching = false;
          launchButton.innerText = 'Launch';
          launchButton.removeAttribute('disabled');
          socket.off('launch:failed', _launchFalseHandler);
          socket.off('launch:true', _launchFalseHandler);
          webSocket.destroy();
        }

        socket.once('launch:true', _launchTrueHandler);
        socket.on('launch:false', _launchFalseHandler);
        socket.emit('launch', {phoneNumber: phoneNumber});

        var _launchTimer = setTimeout(function() {
          _launchFalseHandler('Check your internet connection or retry in a few seconds.');
        }, 10000);

        socket.on('connect', function() {
          clearTimeout(_launchTimer);
        });
      })
      .catch(function(err) {
        launchError.innerText = 'Check your internet connection or retry in a few seconds.';
        _launching = false;
        launchButton.innerText = 'Launch';
        launchButton.removeAttribute('disabled');
        webSocket.close();
      });
    }
  },
  getPhoneNumber: function() {
    var countryCode = this.rootElement.querySelector('.js-phone-country').value;
    var phoneNumber = this.rootElement.querySelector('.js-phone-number').value;

    if (this.validateNumber(phoneNumber) === false) return false;
    var fullPhoneNumber = countryCode + phoneNumber;
    return fullPhoneNumber;
  },
  submitPhoneNumber: function(e) {
    if (e.keyCode === 13) return this.launchSandbox();
  },
  validateNumber: function(number) {
    var launchError = this.rootElement.querySelector('.js-launch-error');
    var errMsg;

    number = number.trim();

    if (number.length === 0) {
      errMsg = 'Phone Number is required';
      launchError.innerText = errMsg;

      return false;
    }

    if (number.length < 8 || helpers.regex.numeric.test(number) === false) {
      errMsg = 'Phone Number is invalid';
      launchError.innerText = errMsg;
      return false;
    }

    launchError.innerText = '';
    return true;
  },
  setCountryCode: function(e) {
    var elem = e.target;

    var newCountryCode = elem.value;
    var newCountryFormat = helpers.phoneNumberFormat[newCountryCode] || '7XXXXXXXX';

    var displayCode = this.rootElement.querySelector('.js-country-code');
    var phoneNumber = this.rootElement.querySelector('.js-phone-number');

    phoneNumber.setAttribute('placeholder', newCountryFormat);

    displayCode.innerText = newCountryCode;
  },
  resolveReconnectAbort: function() {
    var connectionState = stores.connection.get();
    var launchError = this.rootElement.querySelector('.js-launch-error');

    if (connectionState.reconnectAbort === true) {
      launchError.innerText = connectionState.reconnectError;
    }
  },
  initialize: function(options) {
    console.log('Initializing Start View...');
    this.pubSub = pubSub;
    this.data = options.data;

    this.template = Handlebars.templates.startView;
    this.animateIn = 'fadeIn';
    this.animateOut = 'fadeOut';
  }
});
