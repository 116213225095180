var stampit = require('stampit/dist/stampit.full');
var dispatcher = require('../dispatcher');
var _ = require('lodash');
var pubSub = require('../modules/pubSub');
var constants = require('../constants');

var AirtimeActions = stampit()
.init(function() {
  this.pubSub = pubSub;

  /**
   * Dispatches an airtime confirmation actione
   * @param {object} airtime Contains airtime details
   */
  this.airtimeConfirmation = function(airtime) {
    var airtimeObj = _.assignIn(airtime, {
      createdAt: Date.now()
    });

    dispatcher.handleServerAction({
      type: constants.airtime.AIRTIME_CONFIRMATION,
      airtime: airtimeObj
    });
  };
});

var airtimeActions = new AirtimeActions();
module.exports = airtimeActions;
