/* eslint-env browser */
var Handlebars = require('handlebars/runtime');
require('./modules/handlebarsHelpers')(Handlebars);
require('../build/js/appTemplates');
var Router = require('./router');
var app = require('./app');

function setCountries () {
  var countryListNode = document.querySelector('#countryList');

  try {
    var countryList = JSON.parse(countryListNode.innerText);
    window.ansa = {
      countries: countryList,
    };
  } catch(err) {
    window.ansa = {
      countries: {
        KE: {
          name: 'Kenya',
          phone: 254,
          select: true,
        },
      },
    };
  }

  document.body.removeChild(countryListNode);
}

document.addEventListener('DOMContentLoaded', function() {
  setCountries();
  app.init(Router);
});
