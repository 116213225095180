/* eslint-env browser */
var TransitionViewBase = require('./transitionViewBase');
var Handlebars = require('handlebars/runtime');
var helpers = require('../modules/basicHelpers');
var SideNav = require('../modules/sideNav');
var stores = require('../stores');
var Ps = require('perfect-scrollbar');

module.exports = TransitionViewBase.extend({
  id: 'vphone-home-view',
  className: 'js-vphone-home-view vphone-home-view',
  onRender: function(storeChange) {
    console.log('vPhoneHomeView#onRender method triggered');

    var data = {
      phoneNumber: stores.globals.get('phoneNumber'),
      smsUnread: stores.sms.getMeta().unread,
      paymentUnread: stores.payment.getMeta().unread,
      airtimeUnread: stores.airtime.getMeta().unread
    };

    // Hack. Read comment below...
    this.beforeRemove();
    this.$el.html(this.template(data));

    // Dynamic on change bypasses transitionViewBase setup
    // Means we need to call beforeRemove and afterRender
    if (storeChange) this.afterRender();
    return this;
  },
  afterRender: function() {
    // Cache elem for vanilla JS DOM traversal
    this.rootElement = document.getElementById(this.id);
    this.contentArea = this.rootElement.querySelector('.js-content-area');
    this.createVPhoneScrollBar();
    this.loadSideNav();
  },
  beforeRemove: function() {
    this.destroyVPhoneScrollBar();
    this.destroySideNav();
  },
  createVPhoneScrollBar: function() {
    // Use normal mobile scrolling
    if (helpers.isMobile()) return;

    Ps.initialize(this.contentArea, {
      wheelSpeed: 2,
      scrollXMarginOffset: 10,
      maxScrollbarLength: 300
    });

    this.customScroll = true;
  },
  updateVPhoneScrollBar: function() {
    if (this.customScroll) Ps.update(this.contentArea);
  },
  destroyVPhoneScrollBar: function() {
    if (this.customScroll) {
      Ps.destroy(this.contentArea);
      delete this.customScroll;
    }
  },
  loadSideNav: function() {
    var _self = this;

    this.sideNav = new SideNav({
      showButtonEl: _self.rootElement.querySelector('.js-menu-show'),
      sideNavEl: _self.rootElement.querySelector('.js-side-nav'),
      sideNavContainerEl: _self.rootElement.querySelector('.js-side-nav-container')
    });
  },
  destroySideNav: function() {
    if (this.sideNav) this.sideNav.destroy();
  },
  initialize: function() {
    console.log('Initializing VPhone Home View...');

    var _self = this;
    this.template = Handlebars.templates.vPhoneHomeView;
    this.listenTo(stores.sms, 'sms_changed', function() {
      _self.onRender(true);
    });
    this.listenTo(stores.payment, 'payment_changed', function() {
      _self.onRender(true);
    });
    this.listenTo(stores.airtime, 'airtime_changed', function() {
      _self.onRender(true);
    });
    this.animateIn = 'fadeIn';
    this.animateOut = 'fadeOut';
  }
});
